* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --text-color: rgba(48, 48, 48, 0.7);
}

body {
  background-color: #cfcccc;
  margin: 0;
  font-family: "Nunito", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Calendar */
.fc-daygrid-day-frame {
  background-color: #3a3a3a;
  color: #ffffff;
}

.logoBg {
  width: 100px;
  filter: brightness(100);
  margin-right: 16px;
}

.loaderBg {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.colorButton {
  top: 13%;
  left: auto;
  background-color: #fff;
  color: black;
  border-color: #fff;
  cursor: pointer;
  border-radius: 5px 0 0 5px;
  padding: 0.5rem 0;
  transition: right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  /* right: 0; */
  position: fixed;
  box-shadow: -2px 0 5px rgb(0 0 0 / 4%);
  z-index: 999;
}

.themeButton {
  border-radius: 20px;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.subHeader {
  /* background: #ffffff; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  border-bottom: "1px solid black";
}

.subHeader h2 {
  color: black;
  font-size: 1.5rem;
  padding-bottom: 10px;
  display: inline-block;
  font-weight: 500;
}

.pages {
  /* background-color: #ffffff; */
  min-height: 100vh;
}

.normal_button {
  /* min-width: 100px; */
  /* border-radius: 20px; */
  border: none;
  color: #ffffff;
  /* font-size: 12px; */
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  border-radius: 50px;
  outline: initial !important;
  box-shadow: none !important;
  font-size: 0.8rem;
  padding: 0.5rem 1.25rem;
}

.d_flex {
  display: flex;
}

.flex_column {
  flex-direction: column;
}

.justify_content_between {
  justify-content: space-between;
}

.justify_content_center {
  justify-content: center;
}

.align_item_center {
  align-items: center;
}

.bgSub {
  width: 800px;
  position: relative;
  margin: 50px auto;
  padding: 25px;
  border: initial;
  background: #fff;
  border-radius: calc(0.15rem - 1px);
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
}

.padding_2 {
  padding: 0 1.5rem;
}

/* .MuiTreeItem-label:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label,
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent !important;
} */

.loading {
  height: 1rem !important;
  width: 1rem !important;
}

li {
  list-style-type: none;
}

.list-parent li {
  padding: 0.3rem 0px;
  font-size: 1rem;
}

.cat-plus-minus {
  font-size: 22px;
  border: none;
  background: transparent;
  height: 22px;
  cursor: pointer;
  background-color: transparent;
}

.dragHandeler {
  font-size: 25px;
  margin: 10px 10px 0 0;
  color: black;
  cursor: move;
}

.font_13 {
  font-size: 12px !important;
}

p {
  font-size: 14px;
}

.m_auto {
  margin: auto;
}

.MuiAccordion-root:before {
  background-color: transparent;
  display: none;
}

.w_100 {
  width: 100%;
}

.h_100 {
  height: 100%;
}

.MuiAccordion-root:before {
  background-color: transparent;
  display: none;
}

/* .MuiAccordionSummary-root {
  padding: 0 !important;
  cursor: auto !important;
} */

.MuiAccordionSummary-content,
.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.p_10 {
  padding: 10px;
}

.p_20 {
  padding: 20px;
}

.px_1_75 {
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}

.pt_1_50 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px_1 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.px_1_50 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.formField {
  margin-bottom: 1rem;
}

.radioButton {
  display: "flex";
  flex-direction: "row";
}

.padding_1_75 {
  padding: 1.75rem;
}
.mt_8 {
  margin-top: 8px !important;
}

.mt_11 {
  margin-top: 11px !important;
}

.mb_6rem {
  margin-bottom: 0.6rem;
}

.button {
  color: #ffffff !important;
}

.lets-select .MuiOutlinedInput-input {
  padding: 10px 14px !important;
}

.lets-select .MuiInputLabel-outlined {
  transform: translate(12px, 12px) scale(1) !important;
}

.lets-select .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
}

/* The custom */
.custom {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-right: 10px;
  min-height: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  color: #808080;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark,
.checkmark2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  /* background-color: #eee; */
  border-radius: 50%;
}
.checkmark {
  border: 3px solid;
}

/* On mouse-over, add a grey background color */
.custom:hover input ~ .checkmark,
.custom:hover input ~ .checkmark2 {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.custom input:checked ~ .checkmark,
.custom:hover input ~ .checkmark2 {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after,
.checkmark2:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom input:checked ~ .checkmark:after,
.custom input:checked ~ .checkmark2:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom .checkmark:after {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  border-radius: 50%;
}

.custom .checkmark2:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.has-float {
  position: relative;
}

.has-float-label {
  position: absolute;
  cursor: text;
  font-size: 80% !important;
  color: #777777;
  opacity: 1;
  top: -0.4em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
  background-color: #ffffff;
}

.form-control {
  border-radius: 0 !important;
  outline: initial !important;
  box-shadow: none !important;
  font-size: 1rem !important;
  border: 1px solid #d7d7d7;
  background: #fff;
  color: #3a3a3a;
  height: auto;
  padding: 0.5rem 0.75rem;
  line-height: 1;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.custom-control-input {
  left: 1px;
  top: 3px;
  opacity: 0;
  z-index: 1;
}
.custom-control-input,
.custom-control-label {
  outline: initial !important;
  box-shadow: none !important;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* Country Code */
.form-control {
  width: 75px !important;
  border: none !important;
  padding-right: 0;
  font-size: 14px !important;
  font-weight: 100 !important;
}

.flag-dropdown {
  border: none !important;
}
